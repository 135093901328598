import { useState } from "react";
import { closeOutline } from "ionicons/icons";
import {
  IonModal,
  IonHeader,
  IonButton,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSpinner,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { addCoustomRecipe } from "../../services/recipes";
import { IRecipeMeta } from "../../interfaces/RecipeInterface";

import CommonButton from "../../components/commonButton/CommonButton";
import CommonSelect from "../../components/commonSelect/CommonSelect";
import CommonTextBox from "../../components/commonTextbox/CommonTextBox";
import { MeasureType } from "../../constants/commonConstants";

import { customMealValidationSchema } from "../../validationSchemas/mealsValidation";

import toast from "../../utils/toastsMessage";

interface IProps {
  linkedTo?: number;
  openCreateMeal: boolean | undefined;
  setOpenCreateMeal(arg: boolean): void;
  onCreateSubmit(arg: IRecipeMeta): void;
}
const CustomRecipe = (props: IProps) => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(customMealValidationSchema(translation)),
  });

  // Handle custom recipe submit
  const onCustomRecipeSubmit = async (data: IRecipeMeta) => {
    setLoading(true);
    const {
      amount,
      cals,
      carbs,
      fat,
      fiber,
      household_unit,
      keywords,
      measure,
      name,
      protein,
      weight,
      isCustom,
    } = data;
    const meta = {
      cals,
      carbs,
      fat,
      fiber,
      household_unit,
      keywords,
      name,
      protein,
      isCustom,
    };
    const items = [{ amount, measure, weight, meta }];

    const response = await addCoustomRecipe({
      items,
      name,
      linkedTo: props.linkedTo ? props.linkedTo : null,
    });
    if (!response?.data?.success) {
      toast.error(translation(`${response?.data?.errors}`));
    } else {
      toast.success(translation("Custom_recipe_added_successfully"));
      props.onCreateSubmit(data as IRecipeMeta);
      reset({});
    }
    setLoading(false);
  };
  return (
    <IonModal
      isOpen={props.openCreateMeal}
      trigger="open-modal"
      onWillDismiss={() => props.setOpenCreateMeal(false)}
      className="theme-modal"
    >
      <IonHeader>
        <div className="heading">
          <h2>{translation("custom")}</h2>
        </div>
        <div className="modal-closer">
          <IonButton onClick={() => props.setOpenCreateMeal(false)}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      </IonHeader>
      <IonContent className="ion-padding">
        <form
          onSubmit={handleSubmit((data) => {
            onCustomRecipeSubmit(data as IRecipeMeta);
          })}
          noValidate
        >
          <div className="modal-body">
            <IonGrid>
              <IonRow>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Food Name"
                    fieldType="input"
                    control={control}
                    type="text"
                    className="form-control"
                    placeHolder="Fruit Punch Gatorade"
                    name="name"
                    error={errors.name}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Keywords – separate by comma"
                    fieldType="input"
                    control={control}
                    type="text"
                    className="form-control"
                    placeHolder="gatorade, fruit punch"
                    name="keywords"
                    error={errors.keywords}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Servings"
                    fieldType="input"
                    control={control}
                    type="number"
                    className="form-control"
                    placeHolder="0"
                    name="amount"
                    error={errors.amount}
                  />
                </IonCol>
                <IonCol size="12" size-md="6">
                  <CommonTextBox
                    label="Serving Size"
                    fieldType="input"
                    control={control}
                    type="number"
                    className="form-control"
                    placeHolder="0"
                    name="weight"
                    error={errors.weight}
                  />
                </IonCol>
                <IonCol size="12" size-md="6">
                  <CommonSelect
                    label="Unit"
                    className="common-select mb20"
                    dropDownData={MeasureType}
                    control={control}
                    name="measure"
                    multipleSelect={false}
                    error={errors.measure}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Household Unit"
                    fieldType="input"
                    control={control}
                    type="text"
                    className="form-control"
                    placeHolder="Bottle"
                    name="household_unit"
                    error={errors.household_unit}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="KCals (g)"
                    fieldType="input"
                    control={control}
                    type="number"
                    className="form-control"
                    placeHolder="0"
                    name="cals"
                    error={errors.cals}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Fat (g)"
                    fieldType="input"
                    control={control}
                    type="number"
                    className="form-control"
                    placeHolder="0"
                    name="fat"
                    error={errors.fat}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Carbohydrates (g)"
                    fieldType="input"
                    control={control}
                    type="number"
                    className="form-control"
                    placeHolder="0"
                    name="carbs"
                    error={errors.carbs}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Protein (g)"
                    fieldType="input"
                    control={control}
                    type="number"
                    className="form-control"
                    placeHolder="0"
                    name="protein"
                    error={errors.protein}
                  />
                </IonCol>
                <IonCol size="12" size-md="12">
                  <CommonTextBox
                    label="Dietary Fiber (g)"
                    fieldType="input"
                    control={control}
                    type="number"
                    className="form-control"
                    placeHolder="0"
                    name="fiber"
                    error={errors.fiber}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <div>
              <CommonButton type="submit" parentClassName="primary-btn" disabled={loading}>
                {" "}
                {translation("save")}
                {loading ? <IonSpinner name="dots" /> : undefined}
              </CommonButton>
              <CommonButton
                type="button"
                parentClassName="grey-btn"
                name="Reset"
                onClick={() => {
                  reset({});
                }}
              />
            </div>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default CustomRecipe;
