import {
  IonIcon,
  IonLabel,
  IonDatetime,
  IonModal,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { calendarClearOutline, timeOutline } from "ionicons/icons";
import React, { useRef, useState } from "react";
import moment from "moment";

import {
  Controller,
  Control,
  FieldErrors,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import CommonErrorMessage from "./commonError/CommonErrorMessage";
import CommonButton from "./commonButton/CommonButton";
import {
  DATE_FORMAT,
  MONTH_YEAR_FORMAT,
  TIME_FORMAT,
} from "../constants/commonConstants";

interface IProps {
  className?: string;
  label?: string;
  name: string;
  placeHolder?: string;
  control?: Control;
  setValue?: (name: string, value: string) => void;
  presentation?:
    | "date-time"
    | "time-date"
    | "date"
    | "time"
    | "month"
    | "year"
    | "month-year";
  error?: FieldErrors<FieldValues> | FieldError;
  min?: string;
  max?: string;
  disabled?: boolean;
  value?: string;
  required?: boolean;
  showDefaultButtons?: boolean;
  onChange?: (selectedDate: any) => void;
  buttonLabel?: string;
  hasIcon?: boolean;
  defaultValue?: string;
}

const PRESENTATIONS = {
  TIME: "time",
  MONTH_YEAR: "month-year",
  DATE: "date",
  DATE_TIME: "date-time",
};

const DateTimePicker: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { t: translate } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string | null>(null);

  const {
    className = "",
    label,
    name,
    placeHolder = "", // Set a default placeholder
    presentation,
    showDefaultButtons,
    control,
    setValue,
    error,
    min,
    hasIcon,
    buttonLabel,
    max,
    disabled = false,
    value,
    onChange,
    required = false,
    defaultValue,
  } = props;

  const onDismissModal = () => {
    setShowModal(false);

    if (currentValue === null) {
      const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ssZ");
      setCurrentValue(currentDateTime);
      setValue && setValue(name, currentDateTime);
      onChange && onChange(currentDateTime);
    } else if (value === null) {
      setCurrentValue(null);
      setValue && setValue(name, "");
      onChange && onChange("");
    }
  };

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);
  const confirm = () => {
    onDismissModal();
    datetime.current?.confirm();
  };

  const checkDobFormat = () => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (dateRegex.test(value ? value : control?._formValues[name])) {
      const parts = value
        ? value.split("-")
        : control?._formValues[name]?.split("-");
      const dob = new Date(parts[2], parts[0] - 1, parts[1]);
      return moment(dob).format(DATE_FORMAT);
    }

    return moment(value ? value : control?._formValues[name]).format(
      DATE_FORMAT
    );
  };

  return (
    <>
      <div className="form-group">
        {label ? (
          <IonLabel className="font14">
            {label}
            {required ? <sup className="colorRed">*</sup> : null}
            {hasIcon ? (
              <div className="pills success ml-10">
                <p>{buttonLabel ?? 0}</p>
              </div>
            ) : null}
          </IonLabel>
        ) : null}

        <div
          className={presentation === "time" ? "form-control time-control" : ""}
        >
          <CommonButton
            parentClassName="input-date-custom mb14 form-control w-100"
            fill="clear"
            type="button"
            disabled={disabled}
            onClick={() => {
              setShowModal(true);
            }}
            expand="block"
          >
            <p>
              {value || control?._formValues[name]
                ? presentation === PRESENTATIONS.TIME
                  ? moment(control?._formValues[name]).format(TIME_FORMAT)
                  : presentation === PRESENTATIONS.MONTH_YEAR
                  ? moment(value ? value : control?._formValues[name]).format(
                      MONTH_YEAR_FORMAT
                    )
                  : presentation === PRESENTATIONS.DATE_TIME
                  ? moment(value ? value : control?._formValues[name]).format(
                      "hh:mm A MM-DD-YYYY"
                    )
                  : checkDobFormat()
                : placeHolder}
            </p>
            {presentation === "time" ? (
              <IonIcon icon={timeOutline} />
            ) : (
              <IonIcon icon={calendarClearOutline} />
            )}
          </CommonButton>
        </div>

        <CommonErrorMessage error={error} />
      </div>

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => onDismissModal()}
        className={
          presentation === "time"
            ? "time-picker common-modal date-time-modal"
            : "common-modal date-time-modal"
        }
      >
        <Controller
          render={({ field }) => (
            <IonDatetime
              presentation={presentation}
              className={className}
              showDefaultButtons={showDefaultButtons}
              value={value ?? field.value}
              min={min}
              max={max}
              onIonChange={(e) => {
                field.onChange(e);
                ![PRESENTATIONS.TIME, PRESENTATIONS.MONTH_YEAR].includes(
                  presentation as string
                ) && onDismissModal();
              }}
              ref={datetime}
            >
              <IonButtons slot="buttons">
                <IonButton color="primary" onClick={confirm}>
                  {translate("okay")}
                </IonButton>
              </IonButtons>
            </IonDatetime>
          )}
          name={name}
          control={control}
          defaultValue={defaultValue && defaultValue}
        />
      </IonModal>
    </>
  );
};

export default DateTimePicker;