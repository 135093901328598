import { IonContent } from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import MealHistory from "./RecipeHistory";
import NewRecipe from "./NewRecipe";

import { Page } from "../../constants/commonConstants";
import { ISaveMeal } from "../../interfaces/MealInterface";

import "./Meals.scss";

const Recipes = () => {
  const [tab, SetTab] = useState<string | undefined>(Page.NEW_RECIPE);
  const [nameData, setName] = useState<string>("");
  const [saveSearchData, setSaveSearchData] = useState<ISaveMeal[]>([]);

  const { t: translation } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      SetTab(Page.RECIPE_HISTORY);
    }
  }, [location.state]);
  return (
    <IonContent fullscreen>
      <div className="meals-page">
        <div className="container">
          <div className="page-inner">
            {/* common-tab start */}
            <div className="common-tab">
              <div className="tab-menu">
                <ul>
                  <li>
                    <span
                      className={`  ${tab === Page.NEW_RECIPE ? "active" : ""}`}
                      onClick={() => {
                        SetTab(Page.NEW_RECIPE);
                      }}
                    >
                      {translation("new_recipe")}
                    </span>
                  </li>
                  <li>
                    <span
                      className={`  ${
                        tab === Page.RECIPE_HISTORY ? "active" : ""
                      }`}
                      onClick={() => {
                        SetTab(Page.RECIPE_HISTORY);
                      }}
                    >
                      {translation("history")}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="tab-contant">
                <div>
                  {tab === Page.NEW_RECIPE ? (
                    <NewRecipe
                      nameData={nameData}
                      setNameData={setName}
                      saveSearchData={saveSearchData}
                      setSaveSearchData={setSaveSearchData}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <div>{tab === Page.RECIPE_HISTORY ? <MealHistory /> : ""}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};

export default Recipes;
