const config = {
  env: process.env.REACT_APP_NODE_ENV,
  baseUrl: process.env.REACT_APP_BASE_URL,
  imageUrl: process.env.REACT_APP_IMAGE_URL,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  editProfileLink:process.env.REACT_APP_EDIT_PROFILE,
};

export default config;
