import {
  InputChangeEventDetail,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import React from "react";
import {
  Controller,
  Control,
  FieldErrors,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import CommonErrorMessage from "../commonError/CommonErrorMessage";

interface IProps {
  label?: string;
  emptyMessage?: string;
  className: string;
  value?: string | number;
  name: string;
  multipleSelect?: boolean;
  dropDownData: {
    id: string | number;
    value: string | number;
  }[];
  control: Control;
  error?: FieldErrors<FieldValues> | FieldError;
  translateLabels?: boolean;
  disabled?: boolean;
  placeHolder?: string;
  onChange?: (event: CustomEvent<InputChangeEventDetail>) => void;
}

const CommonSelect: React.FC<IProps> = (props) => {
  const {
    label = "",
    emptyMessage = "No options available",
    dropDownData,
    error,
    control,
    name,
    className,
    multipleSelect,
    value,
    translateLabels = false,
    disabled = false,
    placeHolder = "Choose an option",
    onChange,
  } = props;

  const { t: translate } = useTranslation();

  return (
    <div className="form-group">
      <Controller
        render={({ field }) => (
          <>
            <IonLabel>{label}</IonLabel>
            <IonSelect
              placeholder={placeHolder}
              onIonChange={(e) => {
                field.onChange(e);
                onChange && onChange(e);
              }}
              // value={field.value}
              value={field.value ? field.value : "—Select—"}
              disabled={disabled}
              className={className}
              interface="popover"
            >
              {dropDownData.length ? (
                dropDownData.map((data) => (
                  <IonSelectOption
                    key={data.id}
                    value={translateLabels ? data.value : data.id}
                  >
                    {translateLabels ? translate(`${data.value}`) : data.value}
                  </IonSelectOption>
                ))
              ) : (
                <IonSelectOption disabled>{emptyMessage}</IonSelectOption>
              )}
            </IonSelect>
          </>
        )}
        name={name}
        control={control}
        defaultValue={multipleSelect ? [] : value}
      />

      {/* Common error message  */}
      <CommonErrorMessage error={error} />
    </div>
  );
};
export default CommonSelect;
