// import { useTranslation } from "react-i18next";

// const { t: translation } = useTranslation();
// console.log(`${translation("hip_blank")}`,"PPPPPPPPPPP");

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;

export const EventType = [
  { id: "nil", value: "No Action" },
  { id: "activity", value: "Activity" },
  { id: "sunexp", value: "Sun Exposure" },
  { id: "sleep_measure", value: "Measure" },
  { id: "meal", value: "Meals" },
];

export const LIST_RECORDS_LIMIT = 10;

export const weekDays: Array<string> = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const GENDER = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
];
export const METRIC = [
  { id: 0, value: "Imperial & Conventional (in/ft/lb)" },
  // { id: 1, value: "Metric & SI (cm/km/kg)" },
];
export const IMAGE_FILE_SIZE_LIMIT = 20; //  Mb
export const DEFAULT_LAST_LAT = 42.33;
export const DEFAULT_LAST_LONG = -83;
export const PAGE_RANGE_DISPLAYED = 5;
export const ION_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const IMAGE_FILE_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".jpg",
  ".JPEG",
  ".PNG",
];
export const MeasureType = [{ id: "g", value: "g" }];
export const INTENSITY = [
  { id: "resting", value: "Resting" },
  { id: "vlight", value: "Very Light" },
  { id: "light", value: "Light" },
  { id: "moderate", value: "Moderate" },
  { id: "heavy", value: "Heavy" },
];

export const DATE_FORMAT = "MM-DD-YYYY";
export const TIME_FORMAT = "hh:mm A";
export const MONTH_YEAR_FORMAT = "MMM YYYY";

export const Page = {
  NEW_EVENTS: "new_event",
  NEW_SUBJECTIVE: "new_subjective",
  SUBJECTIVE_HISTORY: "subjective_history",
  NEW_ACTIVITY: "new_activity",
  NEW_MEAL: "new_meal",
  MEAL_HISTORY: "meal_history",
  EVENT_LIST: "event_list",
  MANAGE_EVENTS: "manage_events",
  MY_INFO: "my_info",
  PROFILE_PROGRESS: "profile_progress",
  ACTIVITY_HISTORY: "activity_history",
  NEW_LAB: "new_lab",
  LAB_HISTORY: "lab_history",
  NEW_MEASURE: "new_measure",
  MEASUREMENTS_HISTORY: "measurements_history",
  NEW_RECIPE: "new_recipe",
  RECIPE_HISTORY: "recipe_history",
};

export const SUBJECTIVE_FIELDS = [
  {
    name: "mood",
    label: "Mood",
    ratings: [
      "Miserable",
      "Awful",
      "Bad",
      "Not the Best",
      "OK",
      "Alright",
      "Pretty Good",
      "Good",
      "Great",
      "Fantastic",
    ],
  },
  {
    name: "recovery",
    label: "Recovery From Exercise",
    ratings: [
      "5.5 Days",
      "5 Days",
      "4.5 Days",
      "4 Days",
      "3.5 Days",
      "3 Days",
      "2.5 Days",
      "2 Days",
      "1.5 Days",
      "1 Day",
    ],
  },
  {
    name: "sleep_quality",
    label: "Sleep Quality ",
    ratings: [
      "Insomniac",
      "Constantly Waking",
      "Trouble Sleeping",
      "Hit or Miss",
      "OK",
      "Not Bad",
      "Decent Sleep",
      "Good Sleep",
      "Great Sleep",
      "Like the Dead",
    ],
  },
  {
    name: "energy_levels",
    label: "Energy Levels",
    ratings: [
      "Dead",
      "Exhausted",
      "Tired",
      "Somewhat Tired",
      "OK",
      "Decent",
      "Nice",
      "Good",
      "Great",
      "Boing!",
    ],
  },
  {
    name: "libido",
    label: "Libido",
    ratings: [
      "No Interest",
      "Rarely Interested",
      "Interested Monthly",
      "Interested Bi-Weekly",
      "Interested Weekly",
      "Interested Every Few Days",
      "Interested Daily",
      "Interested Several Times a Day",
      "Interested Hourly",
      "Always Interested",
    ],
  },
  {
    name: "hunger_cravings",
    label: "Hunger Cravings",
    ratings: [
      "Always Starving",
      "Hungry Hourly",
      "Craving Snacks Between Meals",
      "Occasional Snack Cravings",
      "OK Waiting for Meals",
      "Mostly Satiated",
      "No Snack Cravings",
      "No Meal Cravings",
      "Skipping Lunch",
      "What Food?",
    ],
  },
  {
    name: "body_heat",
    label: "Body Heat",
    ratings: [
      "Freezing",
      "Very Cold",
      "Cold",
      "Cool",
      "Normal",
      "Warm",
      "Very Warm",
      "Hot",
      "Very Hot",
      "Human Torch",
    ],
  },
  {
    name: "headaches",
    label: "Headaches",
    ratings: [
      "Constant",
      "Several Per Hour",
      "Hourly",
      "Daily",
      "Every Few Days",
      "Weekly",
      "Monthly",
      "Rarely",
      "Long Time Ago",
      "None",
    ],
  },
];

export const SUBJECTIVE_INITIAL = {
  mood: 5,
  sleep_quality: 5,
  recovery: 5,
  energy_levels: 5,
  libido: 5,
  hunger_cravings: 5,
  body_heat: 5,
  headaches: 5,
  linkedTo: undefined,
};

export const GENDER_TYPE = {
  MALE: "male",
  FEMALE: "female",
};

export const TIME = {
  SUNUP: "sunup",
  SUNSET: "sunset",
  SUND: "sund",
  SUNDE: "sunde",
};

export const LAB_FIELDS = [
  {
    identifier: "amsc",
    name: "Salivary Cortisol (AM)",
    metric: "nmol/L",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },
  {
    identifier: "pmsc",
    name: "Salivary Cortisol (PM)",
    metric: "nmol/L",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },

  {
    identifier: "vd3",
    name: "Vitamin D3",
    metric: "mmol/L",
    standard: "ng/mL",
    conversion: "mgdlToMmol",
  },

  {
    identifier: "mela",
    name: "Melatonin (AM)",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "melp",
    name: "Melatonin (PM)",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "ngmlToPmol",
  },
];
export const MESURMENT_FIELD = [
  {
    identifier: "fas",
    title: "Time to fall asleep? (min)",
    placeHolder: "32",
  },
  {
    identifier: "ttsb",
    title: "How much time spent in bed? (hrs)",
    placeHolder: "36",
  },
  {
    identifier: "tss",
    title: "How much time spent sleeping? (hrs)",
    placeHolder: "12",
  },
  {
    identifier: "twp",
    title: "How many times do you wake up? (number)",
    placeHolder: "160",
  },
  {
    identifier: "gbts",
    title: "How long to get back to sleep? (min)",
    placeHolder: "160",
  },
  {
    identifier: "gettingup",
    title: "Difficulty getting up in the morning?",
  },
  {
    identifier: "stayingawake",
    title: "Difficulty staying awake during the day?",
  },
  {
    identifier: "nap",
    title: "Need to nap to get through the day? ",
  },
];
export const Toggle: Array<string> = [
  "email_toggle",
  "sms_toggle",
  "web_notification_toggle",
];

