export const ROUTES = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  EVENTS: "/events",
  PROFILE: "/profile",
  MEALS: "/meals",
  ACTIVITIES: "/activities",
  LABS: "/labs",
  MEASUREMENTS: "/measurements",
  SUBJECTIVE: "/subjective",
  HELP: "/help",
  NOTIFICATION: "/notification",
  RECIPE: "/recipe",
  ADDSUNEXPOSURE: "/add-sun-exposure",
  UNAUTHORIZE: "/*",
};
export const DONT_SHOW_AFTER_LOGIN_ROUTES = [ROUTES.LOGIN];

export const USER_ROUTES = [
  ROUTES.DASHBOARD,
  ROUTES.EVENTS,
  ROUTES.PROFILE,
  ROUTES.MEALS,
  ROUTES.ACTIVITIES,
  ROUTES.LABS,
  ROUTES.MEASUREMENTS,
  ROUTES.SUBJECTIVE,
  ROUTES.HELP,
  ROUTES.ADDSUNEXPOSURE,
  ROUTES.RECIPE,
  ROUTES.NOTIFICATION,
];

export default ROUTES;
