import { useEffect } from "react";
import { closeOutline } from "ionicons/icons";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import CommonButton from "../../components/commonButton/CommonButton";
import CommonSelect from "../../components/commonSelect/CommonSelect";
import CommonTextBox from "../../components/commonTextbox/CommonTextBox";

import { addMealValidationSchema } from "../../validationSchemas/mealsValidation";
import { IMeta } from "../../interfaces/MealInterface";

interface IProps {
  setOpenSearchMealDetail(arg: boolean): void;
  onSubmit(arg: IMeta): void;
  measureList: { id: string | number; value: string | number }[];
  openSearchMealDetail: boolean;
}

const AddRecipe = (props: IProps) => {
  const { t: translation } = useTranslation();

  const {
    control,
    handleSubmit,
    formState,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addMealValidationSchema(translation)),
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({});
    }
  }, [formState, reset]);

  return (
    <IonModal
      isOpen={props.openSearchMealDetail}
      trigger="open-modal"
      onWillDismiss={() => props.setOpenSearchMealDetail(false)}
      className="theme-modal"
    >
      <IonHeader>
        <div className="heading">
          <h2>{translation("add_meal")}</h2>
        </div>
        <div className="modal-closer">
          <IonButton onClick={() => props.setOpenSearchMealDetail(false)}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      </IonHeader>
      <IonContent className="ion-padding">
        <form
          onSubmit={handleSubmit((data) => props.onSubmit(data as IMeta))}
          noValidate
        >
          <div className="modal-body">
            <CommonTextBox
              label="Servings"
              fieldType="input"
              control={control}
              type="number"
              className="form-control"
              placeHolder="0"
              name="amount"
              error={errors.amount}
            />
            <CommonSelect
              label="Household Measure"
              className="common-select mb20"
              dropDownData={props.measureList}
              control={control}
              name="measure"
              multipleSelect={false}
              error={errors.measure}
            />
            <CommonButton
              type="submit"
              parentClassName="primary-btn"
              name="Add meals"
            ></CommonButton>
          </div>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default AddRecipe;
